.bodyLanding {
	background: rgba(21, 26, 53, 1);
	background-image: url("../../assets/images/3de15d9e-fondo-08.svg");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	border-style: none none none none;
	border-color: none;
	border-radius: 0px;
	width: 100%;
	height: 949px;
	position: absolute;
	flex: auto;
}

.contentLanding {
	position: relative;
	margin-top: 5rem;
	margin-left: 10%;
	margin-right: 10%;
}
.contentLandingItems {
	position: relative;
	margin-top: 10rem;
	margin-left: 10%;
	margin-right: 10%;
}
.contentLandingFoundation {
	position: relative;
	margin-top: 5rem;
	margin-left: 10%;
	margin-right: 10%;
}
.contentLandingVideo {
	position: relative;
	margin-top: 5rem;
	margin-left: 10%;
	margin-right: 10%;
	text-align: center;

}
.contentLandingTutorial {
	position: relative;
	margin-top: 10rem;
	margin-left: 10%;
	margin-right: 10%;
	margin-bottom: 5rem;
	
}
.iphone {
	width: 302px;
	height: 603px;
	margin-left: -2rem;
 }
 .imageFoundation {
	position: relative;
	margin-top: -5rem;
	z-index: -10;
  }
  .imageStartTutorial {
	z-index: -10;
	position: relative;
	margin-top: -5rem;
}
/* Mobile App */
@media only screen and (max-width: 768px) {
	.bodyLanding {
		background: rgba(21, 26, 53, 1);
		width: 80%;
		height: 949px;
		margin-bottom: auto;
		position: relative;
		flex: auto;
		flex-direction: row;
	}
	.iphone {
		margin-left: 0;
	 }
	 .imageFoundation {
		position: relative;
		margin-top: 0rem;
		margin-left: -2rem;
		width: 20rem;
	  }
	  .imageStartTutorial {
		z-index: -10;
		position: relative;
		margin-top: -1rem;
		width: 15rem;
	}
}
/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .bodyLanding {
		width: 100%;
	}
	.iphone {
		margin-left: 0;
	 }
	 .imageStartTutorial {
		z-index: -10;
		position: relative;
		margin-top: -10rem;
		width: 15rem;
	}
}

.titleLanding{
	text-align: left;
	font-family: TT Norms;
	font-style: normal;
	line-height: 58px;
	font-feature-settings: 'liga' off;
	color: #EAE8F1;
  }
  .titleLandingVideo{
	text-align: center;
	font-family: TT Norms;
	font-style: normal;
	line-height: 58px;
	font-feature-settings: 'liga' off;
	color: #EAE8F1;
	
  }
 
  
  .logoStores {
	  display: flex;
	  flex-direction: row;
	  width: 120%;
	  margin-top: 10%;
  }
  .logoStoreiOS{
	 width: 120px;
  }
  .logoStoreGoogle{
	width: 120px;
	margin-right: 5%;
 }

 .divider {
	width: 100%; 
	border: 1px solid #767486;
	margin-bottom: 5%;
 }
 .itemLogo {
	width: 100px;
	height: 100px;
 }
 .item {
	 color: #ffffff;
	 text-align: center;
	 width: 90%;
 }

.video{
	width: 74px;
	height: 74px;
	margin-top: 3rem;
	margin-bottom: 1rem;
	display:block;
    margin-left: auto;
    margin-right: auto;
}
.labelVideo{
	position: relative;
	text-decoration: none;
	text-align: center;
	width: 50%;
	height: 40vh;
	color: #767486;
	margin-top: 4%;
	margin-bottom: 4%;
}
@media only screen and (max-device-width: 768px) {
	.labelVideo{
		position: relative;
		text-decoration: none;
		text-align: center;
		width: 80%;
		height: 40vh;
		color: #767486;
		margin-top: 4%;
		margin-bottom: 4%;
	}
}
.videoDiv{
	text-align: center;
	color: #767486;
	text-decoration: none;
	font-size: 12px;
	width: 50%;
}

.footer{
	position: absolute;
	bottom: 0;
}
.bodyText {
	color: #767486;
	font-family: TT Norms;
	font-style: normal;
}