.headerBackground {
  display: flex;
  flex-direction: row;
}

.logoHeader{
  position: relative;
  width: 150px;
  height: 28px;
  margin-top: 2rem;
  left: 15rem;
}

.tutorial {
  position: relative;
  margin-left: 20rem;
  margin-top: 2.5rem;
  font-family: TT Norms;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;
  white-space: nowrap;
}
@media only screen and (max-width: 768px) {

  .logoHeader{
    position: relative;
    margin-top: 2rem;
    left: 3rem;
  }
  .tutorial {
    position: relative;
    margin-left: 6rem;
    margin-top: 2.5rem;
    font-size: 16px;
    display: inline-block;
    white-space: nowrap;
  }
}
/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {

  .logoHeader{
    position: relative;
    margin-top: 2rem;
    left: 8rem;
  }
  .tutorial {
    position: relative;
    margin-left: 10rem;
    margin-top: 2.5rem;
    font-size: 18px;
  }
}
