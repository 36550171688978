.containerProfile{
    display: flex;
    width: 70%;
    height: 138px;
    background: rgba(217, 213, 228, 0.7);
    border-radius: 6px;
    margin-bottom: 2%;
}
.containerProfileOn{
    display: flex;
    width: 70%;
    height: 300px;
    background: #EAE8F1;
    border-radius: 6px;
    margin-bottom: 2%;
    padding-bottom: 2%;
}
.containerProfileOn0{
    display: flex;
    width: 70%;
    height: 250px;
    background: #EAE8F1;
    border-radius: 6px;
    margin-bottom: 2%;
    padding-bottom: 0%;
}
.panelImageOn {
    position: relative;
    margin-top: 2rem;
    margin-left: 2rem;
    width: 74px;
    height: 74px;
}
.panelImageOff {
    position: relative;
    margin-top: 2rem;
    margin-left: 2rem;
    width: 74px;
    height: 74px;
}
.logoStoresPanel {
    display: flex;
    flex-direction: row;
    float: right;
  }
  .logoStoreiOSPanel{
   width: 48px;
   height: 48px;
   margin-left: 20px;
  }
  .logoStoreGooglePanel{
    width: 48px;
    height: 48px;
    margin-right: 20px;
  }
@media only screen and (max-width: 768px) {
    .containerProfile{
        width: 90%;
        height: 190px;
        padding-bottom: 5%;
    }
    .containerProfileOn{
        width: 90%;
        height: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .containerProfileOn0{
        width: 90%;
        height: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .panelImageOn {
        display: block;
        margin: auto;
        margin-top: 1rem;
        margin-right: 10%;;
    }
    .panelImageOff {
        display: block;
        margin: auto;
        margin-top: 1rem;
        margin-right: 10%;
    }
}
/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
    .containerProfile{
        width: 75%;
        height: 190px;
        text-align: center;
        justify-content: center;
        margin-bottom: 5%;
        position: relative;

    }
    .containerProfileOn{
        position: relative;
        width: 75%;
        height: 100%;
        text-align: center;
        justify-content: center;
        margin-bottom: 5%;
        left: 0%;
    }
    .containerProfileOn0{
        position: relative;
        width: 75%;
        height: 100%;
        text-align: center;
        justify-content: center;
        margin-bottom: 1%;
        left: 0%;
    }
  }
  
.panelTitle {
    font-family: TT Norms;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    /* or 113% */
    font-feature-settings: 'liga' off;
    color: #7B788A;
    text-align: left;
    position: relative;
    top: 2rem;
    left: 2rem
}
.panelTitleOn {
    font-family: TT Norms;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    /* or 113% */
    font-feature-settings: 'liga' off;
    color: #767486;
    text-align: left;
    position: relative;
    top: 2rem;
    left: 2rem
}
@media only screen and (max-width: 768px) {
    .panelTitleOn {
        text-align: center;
        top: 1rem;
    }
    .panelTitle {
        text-align: center;
        top: 0rem;
    }
    .buttonPanel {
        float: left;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
    .panelTitleOn {
        text-align: center;
    }
    .panelTitle {
        text-align: center;
    }
    .buttonPanel {
        float: left;
        left: 2rem;
    }
}
.bodyTextOn {
    position: relative;
    font-size: 17px;
    color: #767486;
    font-family: TT Norms;
    font-style: normal;
}
.bodyTextOff {
    visibility: hidden;
    display: none;
    font-family: TT Norms;
    font-style: normal;
}
