.logoFooter{
    position: relative;
    width: 150px;
    height: 28px;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  .footerBackground {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .textFooter {
    position: relative;
    margin-top: 2%;
    font-family: TT Norms;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #767486;
  }
  .dividerFooter {
    border: 1px solid #767486;
    width: 80%;
 }
  