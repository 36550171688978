@font-face {
  font-family: 'TTNorms';
  src: local('TTNorms'), url(./assets/fonts/TTNorms-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'TTNorms';
    font-weight: 900;
    src: local('TTNorms'), url(./assets/fonts/TTNorms-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'TTNorms';
    font-weight: 900;
    src: local('TTNorms'), url(./assets/fonts/TTNorms-Black.otf) format('opentype');
}
body {
  background-color: rgba(21, 26, 53, 1);
}

code {
  font-family: 'TTNorms', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
