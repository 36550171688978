
.content {
  position: relative;
  margin-top: 4rem;
  padding-bottom: 5rem;
}
.titleHome{
  text-align: center;
  width: 68%;
  font-family: TT Norms;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 58px;
  text-align: center;
  font-feature-settings: 'liga' off;
  color: #EAE8F1;
}
.panels{
  position: relative;
  margin-top: 2rem;
}

.restartProcess {
  position: relative;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 1%;
}
.restartButton {
  margin: 0 auto;
}
.bodyText {
	color: #ffffff;
	font-family: 'TTNorms';
	font-style: normal;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .titleHome{
    width: 100%;
  }
}
/* Tablet */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .titleHome{
      width: 90%;
    }
  }
